import {
  Card, CardActionArea, Grid, Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { useNavigate } from 'react-router-dom';
import useStyles from './styles';
import Panel from '../panel';

const SummaryTitleBar = ({ isParentUserType }) => {
  const { classes } = useStyles();

  return (
    <Grid container className={classes.summaryTitleBar}>
      <Grid item xs={3} sm={isParentUserType ? 4 : 3}>
        <Typography variant="body1" className={classes.cardTitle}>Date</Typography>
      </Grid>
      <Grid item xs={3} sm={isParentUserType ? 3 : 2}>
        <Typography variant="body1" className={classes.cardTitle}>Amount</Typography>
      </Grid>
      {!isParentUserType && (
        <Grid item xs={2}>
          <Typography variant="body1" className={classes.cardTitle}>Total Hours</Typography>
        </Grid>
      )}
      <Grid item xs={4} sm={isParentUserType ? 5 : 4}>
        <Typography variant="body1" className={classes.cardTitle}>Status</Typography>
      </Grid>
    </Grid>
  );
};

SummaryTitleBar.propTypes = {
  isParentUserType: PropTypes.bool.isRequired,
};

const SummaryCard = ({
  date, amount, hours, status, id, url, isParentUserType,
}) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  // Convert pence to pounds.
  const amountValue = amount ? `£${amount / 100}` : '£0';

  const onClick = () => {
    const linkToStripe = (status !== 'draft') && isParentUserType && url;

    if (linkToStripe) {
      window.open(url, '_blank');
    } else {
      navigate(id);
    }
  };

  const message = isParentUserType && status !== 'paid' ? 'Review and Pay' : 'Review';

  return (
    <Card className={classes.card}>
      <CardActionArea className={classes.cardArea} onClick={onClick}>
        <Grid container>
          <Grid item xs={4} display={{ sm: 'none' }}>
            <Typography variant="body1" className={classes.cardTitle}>Date</Typography>
          </Grid>
          <Grid item xs={8} sm={isParentUserType ? 4 : 3} className={classes.date}>
            <CalendarTodayIcon className={classes.calendarIcon} fontSize="small" />
            <Typography variant="body1" className={classes.cardText}>{dayjs(date).format('DD MMMM YYYY')}</Typography>
          </Grid>
          <Grid item xs={4} display={{ sm: 'none' }}>
            <Typography variant="body1" className={classes.cardTitle}>Amount</Typography>
          </Grid>
          <Grid item xs={8} sm={isParentUserType ? 3 : 2}><Typography variant="body1" className={classes.cardText}>{amountValue}</Typography></Grid>
          {!isParentUserType && (
            <>
              <Grid item xs={4} display={{ sm: 'none' }}>
                <Typography variant="body1" className={classes.cardTitle}>Total Hours</Typography>
              </Grid>
              <Grid item xs={8} sm={2}><Typography variant="body1" className={classes.cardText}>{hours}</Typography></Grid>
            </>
          )}
          <Grid item xs={4} display={{ sm: 'none' }}>
            <Typography variant="body1" className={classes.cardTitle}>Status</Typography>
          </Grid>
          <Grid item xs={8} sm={isParentUserType ? 5 : 4} className={classes.statusText}>
            <Typography variant="body1" className={classes.cardText}>{status}</Typography>
            <Typography variant="body1" className={classes.link}>{message}</Typography>
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
};

SummaryCard.defaultProps = {
  url: null,
};

SummaryCard.propTypes = {
  date: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  hours: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  url: PropTypes.string,
  isParentUserType: PropTypes.bool.isRequired,
};

const PaymentsSummary = ({
  invoices, totalEarned, totalHoursTutored, isParentUserType,
}) => {
  const { classes } = useStyles();
  // Convert pence to pounds.
  const totalEarnedAmount = totalEarned ? `£${totalEarned / 100}` : 0;

  const sortedInvoices = invoices?.slice().sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

  return (
    <>
      {!isParentUserType && (
      <Grid container spacing={3} className={classes.totals}>
        <Grid item xs={12} sm={6}>
          <Panel title="Total Paid" value={totalEarnedAmount} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Panel title="Total Hours Tutored" value={totalHoursTutored} />
        </Grid>
      </Grid>
      )}
      <div className={classes.cardList}>
        <SummaryTitleBar isParentUserType={isParentUserType} />
        {invoices?.length
          ? (
            sortedInvoices?.filter((invoice) => invoice?.status !== 'void')?.map((invoice) => (
              <SummaryCard
                date={invoice?.updatedAt}
                amount={invoice?.total}
                hours={invoice?.metadata?.totalHoursTutored}
                status={`${invoice?.status.charAt(0).toUpperCase()}${invoice?.status.slice(1)}`}
                id={invoice?.id}
                url={invoice?.url}
                key={invoice?.id}
                isParentUserType={isParentUserType}
              />
            ))
          ) : (
            <Typography variant="body1" className={classes.noDataText}>
              No payment data.
            </Typography>
          )}
      </div>
    </>
  );
};

PaymentsSummary.defaultProps = {
  invoices: null,
  totalEarned: 0,
  totalHoursTutored: 0,
};

PaymentsSummary.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.shape({
    total: PropTypes.number,
    lines: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        amount: PropTypes.number,
        status: PropTypes.string,
        updatedAt: PropTypes.string,
      }),
    ),
  })),
  totalEarned: PropTypes.number,
  totalHoursTutored: PropTypes.number,
  isParentUserType: PropTypes.bool.isRequired,
};

export default PaymentsSummary;
