const config = {
  apiName: 'path-education',
  parentalAgreementVersion: 'parental-agreement-2023.03.20',
  tutorAgreementVersion: 'tutor-agreement-2023.03.20',
  path: {
    signup: '/signup',
    signupBasic: '/signup-basic',
    completeRegister: '/complete-signup',
    getTutors: '/getTutors',
    verifyTutor: '/verifyTutor',
    agreement: '/agreement',
  },
  UserType: {
    ADMIN: 'admin',
    TUTOR: 'tutor',
    PARENT: 'parent',
    LEARNER: 'learner',
  },
};

export default config;
